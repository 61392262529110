.orderCard {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
}

.orderCard h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.orderCard h3 {
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 10px;
}

.orderItem {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.orderItem:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.sectionTitle {
    padding: 10px;
    cursor: pointer;
    background-color: #f0f0f0;
}

.sectionContent {
    padding: 10px;
    background-color: #fff;
}