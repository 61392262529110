.container {
    margin: 0px 100px 100px;
    display: flex;
    flex-direction: column;
}

.info {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
}

.sellerCard,
.subscriptionCard,
.ordersCard,
.actionsCard {
    display: flex;
    flex-direction: column;
    background: var(--white, #fafafa);
    border-radius: 20px;
    box-shadow: 0 2px 8px 2px #3737741a;
    padding: 30px;
    gap: 8px;
    min-width: 300px;
    align-items: center;
}

.block {
    margin-top: 12px;
    padding: 12px;
    align-self: center;
    border: 1px solid var(--botique-blue);
    border-radius: 12px;
    color: var(--botique-blue);
}

.block:hover {
    color: red;
    border: 1px solid red;
    box-shadow: 0 2px 8px 2px red;
    transition: 400ms;
}

.storesContainer {
    margin: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stores {
    display: flex;
    gap: 12px;
}

.store {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    border: 1px solid black;
    border-radius: 12px;
    max-width: 400px;
}

.storeInfo {
    display: flex;
    gap: 8px;
}

.storeInfo .storePic {
    max-width: 240px;
}

.storePic img {
    width: 100%;
}

.storeDetail {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-wrap: wrap;
}