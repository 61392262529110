.usersTable {
    border-collapse: collapse;
    width: 100%;
}

.usersTable th,
.usersTable td {
    border: 1px solid #ddd;
    padding: 8px;
    cursor: pointer;
}

.usersTable th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.usersTable tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.usersTable tbody tr:hover {
    background-color: var(--botique-blue);
    cursor: pointer;
}

.view {
    max-width: 25px;
    max-height: 25px;
}