.container {
    display: flex;
    justify-content: center;
}

.orders {
    width: 60%;
    max-width: 1200px;
    overflow: hidden;
}

.tools {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

@media (max-width: 700px) {
    .tools {
        flex-wrap: wrap;
    }
}

.searchBox {
    display: flex;
    align-items: center;
    width: 100%;
}

.searchBox input {
    width: 100%;
    /* min-width: 280px; */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}