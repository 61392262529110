.container {
    margin: 0px 40px 100px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}

.tools {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    min-width: 200px;
}

@media (max-width: 700px) {
    .tools {
        flex-wrap: wrap;
    }
}

.searchBox {
    display: flex;
    align-items: center;
    width: 100%;
}

.searchBox input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.loader {
    position: absolute;
    left: 50%;
    top: 50vh;
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left: 8px solid #007bff;
    /* Цвет кольца - синий */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    /* Анимация вращения */
    margin: 0 auto;
    /* Центрирование по горизонтали */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}