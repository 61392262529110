.botMessage {
    width: 100%;
    display: flex;
    justify-content: right;
}

.clientMessage {
    width: 100%;
    display: flex;
    justify-content: left;
}

.message {
    max-width: 520px;
    display: flex;
    flex-direction: column;
    border: 1px solid royalblue;
    border-radius: 12px;
    padding: 12px;
}

.message.order {
    background-color: yellow;
}