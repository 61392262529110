/* StoreAndProducts.module.css */
.storeAndProducts {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.products {
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Стили для блока с информацией о магазинах */
.storeInfo {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
    max-width: 700px;
}

.storeInfo h2 {
    margin-bottom: 10px;
    color: #333;
}

.storeInfo p {
    margin-bottom: 10px;
    color: #555;
    line-height: 1.5;
    /* Межстрочный интервал */
}

.storeInfo img {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-top: 15px;
}

/* Стили для блока с информацией о товарах */
.productInfo {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 20px;
    transition: box-shadow 0.3s ease;
    max-width: 800px;
}

.productInfo:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* Тень при наведении */
}

.productInfo h3 {
    margin-bottom: 10px;
    color: #333;
    cursor: pointer;
    /* Указатель при наведении на заголовок */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productInfo h3 .toggleBtn {
    font-size: 18px;
    margin-left: 10px;
}

.productInfo .productCards {
    margin-top: 15px;
    /* Отступ между заголовком и списком товаров */
}

.productCard {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-bottom: 15px;
}

.productCard h4 {
    margin-bottom: 10px;
    color: #333;
}

.productCard p {
    margin-bottom: 10px;
    color: #555;
    line-height: 1.5;
    /* Межстрочный интервал */
}

.productCard img {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
    /* Небольшой отступ между изображениями */
}

.productCard:last-child {
    margin-bottom: 0;
    /* Убираем отступ у последнего блока товара */
}

/* Стили для блока с фотографиями товара */
.photos {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

.photo {
    width: 100%;
    max-width: 200px;
    border-radius: 5px;
}

.photo img {
    width: 100%;
    height: auto;
}