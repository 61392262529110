@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"), url("./assets/fonts/Montserrat-VariableFont_wght.ttf");
}

* {
    font-family: "Montserrat"
}

body {
    margin: 0px;
}

:root {
    --botique-blue: #3cadff;
}