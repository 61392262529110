.header {
    padding-top: 10px;
    background-color: var(--botique-blue);
    text-align: center;
}

.header span {
    font-size: 40px;
}

.menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.menu li {
    display: list-item;
    list-style: none;
    padding: 24px;
    cursor: pointer;
    transition: 500ms;
}

.menu li:first-child {
    border-radius: 10px 0px 0px;
}

.menu li:last-child {
    border-radius: 0px 10px 0px 0px;
}

.menu li:hover {
    background-color: white;
}