.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
    align-items: center;
}

.pagination span {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.pagination span.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

.pagination span:hover {
    background-color: #f0f0f0;
}