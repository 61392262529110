.scoresContainer {
    background: var(--white, #fafafa);
    border-radius: 20px;
    box-shadow: 0 2px 8px 2px #3737741a;
    padding: 30px;
    display: flex;
    align-items: center;
}

.data {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.data hr {
    width: 100%;
    margin: 0px;
}

.data span {
    cursor: pointer;
}

.data span:hover {
    text-decoration: underline;
}

.data span strong {
    font-weight: 600;
}

.data span:first-child {
    text-align: center;
}

.data span:first-child:hover {
    text-decoration: none;
}

.data span:first-child strong {
    font-weight: 800;
}