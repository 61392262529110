.card {
    border-radius: 20px;
    background: var(--white, #fafafa);
    box-shadow: 0 2px 8px 2px #3737741a;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 8px;
}

.timeInfo span {
    font-size: 14px;
}

.details {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.buttons {
    display: inline-flex;
    align-items: center;
    justify-content: right;
    gap: 2px;
    padding-right: 30px;
}

.buttons span,
img {
    cursor: pointer;
}

.buttons span:hover {
    color: var(--botique-blue);
    transition: 500ms;
}